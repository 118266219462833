@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'),
    url(./fonts/RobotoCondensed-Regular.ttf) format('truetype');
}
* {
  font-family: 'RobotoCondensed';
}

.text-modded {
  font-family: 'RobotoCondensed';
  letter-spacing: 6px;
}

.wrapper-loader {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle-loader {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #2ca8ff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle-loader:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle-loader:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow-loader {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow-loader:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow-loader:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper-loader span {
  position: absolute;
  top: 75px;
  font-family: 'RobotoCondensed';
  font-size: 20px;
  letter-spacing: 13px;
  color: #2ca8ff;
  left: 15%;
}
